import React, { useState, useEffect } from "react";
import PageHeader from "../components/PageHeader";
import PageLayout from "../components/PageLayout";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import instance from "../utilities/axios.js";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../redux/reducers/rootReducer";
import { updateShopObject } from "../redux/actions/shopActions";
import { graphql, navigate } from "gatsby";
import { constants } from "../redux/constants";
import { Trans, useTranslation } from "react-i18next";
import { formatPhoneData } from "../utilities/utils";
import { planIncludesDescription } from "../utilities/constants";
import ProratedPlanModal from "../components/ProratedPlanModal";
import SimCardDetail from "../components/SimCardDetail";
import axios from "axios";
import moment from "moment";
import { Crisp } from "crisp-sdk-web";

export default function infoFillup(state) {
  let shopDetails = useSelector((state: RootState) => {
    return state.shop;
  });

  const dispatch = useDispatch();
  const [getTotalPostpaidPayLoad, setGetTotalPostpaidPayLoad] = useState(null);
  const [getTotalPrepaidPayLoad, setGetTotalPrepaidPayLoad] = useState(null);
  const [step, setStep] = useState<number>(1);
  const { t } = useTranslation();

  const [oaId, setOaId] = useState("");
  const dispatchShop = (obj) => {
    dispatch(updateShopObject(obj) as any);
  };

  useEffect(() => {
    FromExternalLinkAndHandle(); // here is the logic we needa take care of
  }, []);

  function fetchActivationData(id, type) {
    let activationCheckUrl = `https://phoneboxapi.azurewebsites.net/SimplePage/${type}ActivationCompletingValidate.aspx?refno=${id}`;

    axios({ method: "get", url: activationCheckUrl })
      .then((res) => {
        return res.data;
      })
      .then((html) => {
        activationLinkRedirect(html);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function activationLinkRedirect(res) {
    if (
      typeof res === "string" &&
      res.includes("And the activation is expired.")
    ) {
      alert(
        "Your activation link has expired. Redirecting you to the home page..."
      );
      setTimeout(
        (window.location.href = "https://gophonebox.com/plans/"),
        1000
      );
    } else if (typeof res === "string" && res === "") {
      window.location.href = "https://gophonebox.com/payment-thank-you/";
    } else {
      return;
    }
  }

  function FromExternalLinkAndHandle() {
    // Postpaid is using oaID, prepaid is using paId
    let oaId = new URLSearchParams(window.location.search).get("oaId");

    let paId = new URLSearchParams(window.location.search).get("paId");

    // 135564
    if (oaId) {
      fetchActivationData(oaId, "Postpaid");
      const url = "/Activation/PostpaidBillingInfo?refNo=" + oaId;
      dispatchShop({ isPostpaid: true });
      dispatchShop({ oa_paId: oaId });
      setOaId(oaId);
      getBillingInfo(url);
    } else if (paId) {
      fetchActivationData(paId, "Prepaid");
      const url = "/Activation/PrepaidBillingInfo?refNo=" + paId;
      dispatchShop({ isPostpaid: false });
      dispatchShop({ oa_paId: paId });
      setOaId(paId);
      getBillingInfo(url);
    } else {
      return;
    }
  }

  const isTelusPlan = shopDetails.planCarrier.toLowerCase() === "telus";

  const getBillingInfo = (url) => {
    instance.post(url).then((response) => {
      const data = response.data[0];
      const planData = data.dataCap
        ? data.dataCap.split(".")[0]
        : data.Data / (1024 * 1024);
      const planId = data.pfId ? data.pfId : data.PlanId;
      const startDate = data.prorate_Start_Str
        ? data.prorate_Start_Str
        : data.charge_duration.split("~")[0];
      dispatchShop({ startDateLocal: startDate });
      dispatchShop({ startDate: startDate });
      dispatchShop({ planId });
      dispatchShop({
        country:
          data.Currency === constants.CANADA_CURRENCY
            ? constants.CANADA
            : constants.USA,
      });
      dispatchShop({ planCarrier: data.carrier });
      dispatchShop({ simcard_fee: data.simcard_fee });
      dispatchShop({ subtotal: data.subtotal });
      dispatchShop({ total: data.total });
      dispatchShop({ prorate_duration: data.prorate_duration });
      dispatchShop({ prorate_fee: data.prorate_fee });
      dispatchShop({ plan_fee: data.plan_fee });
      dispatchShop({ charge_duration: data.charge_duration });
      dispatchShop({ gst_amt: data.gst_amt });
      dispatchShop({ pst_amt: data.pst_amt });
      dispatchShop({ pst_rate: data.pst_rate });
      dispatchShop({ gst_rate: data.gst_rate });
      dispatchShop({ data: formatPhoneData(planData) });

      if (data.rogersplan || data.Carriers === "R") {
        dispatchShop({ planCarrier: "Rogers" });
      } else if (data.telusplan) {
        dispatchShop({ planCarrier: "Telus" });
      } else if (data.bellplan) {
        dispatchShop({ planCarrier: "Bell" });
      } else if (data.Carriers) {
        dispatchShop({ planCarrier: data.Carriers });
      }

      const currency = data.currency ? data.currency : data.Currency;
      dispatchShop({ currency });
      dispatchShop({ sfFee: data.sfFee });

      let planDuration = 30;
      if (data.planTypeD) {
        planDuration = data.planTypeD;
      } else if (data.PlanTypeD) {
        planDuration = data.PlanTypeD;
      }

      dispatchShop({ planTypeD: planDuration });

      let plans = [];
      plans.push({
        planId: planId,
        plan_fee: data.plan_fee,
        carrier: shopDetails.planCarrier,
        data: formatPhoneData(planData),
        planTypeD: planDuration,
      });
      dispatchShop({ plans });
    });
  };

  const getServiceCountryId = () => {
    switch (shopDetails.country) {
      case constants.USA:
        return 247;
      default:
        return 42;
    }
  };

  const goToCheckout = () => {
    navigate("/checkout/", { state: oaId });
  };

  const valueFormatter = (variable) => {
    if (
      shopDetails[variable] &&
      typeof shopDetails[variable] === "string" &&
      variable === "startDateLocal"
    ) {
      return moment(shopDetails[variable]).format("YYYY-MM-DD");
    }

    if (
      variable === "simcard_fee" ||
      variable === "prorate_fee" ||
      variable === "sfFee" ||
      variable === "promocredit" ||
      variable === "plan_fee" ||
      variable === "subtotal" ||
      variable === "total"
    ) {
      return shopDetails[variable] && typeof shopDetails[variable] === "number"
        ? shopDetails[variable].toFixed(2)
        : "0.00";
    }

    return shopDetails[variable];
  };

  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < 768) {
        setMobile(true);
      } else setMobile(false);
    };
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, [mobile]);

  const [showProratedPlanModal, setShowProratedPlanModal] = useState(
    shopDetails.isPostpaid
  );

  return (
    <PageLayout>
      <title>PhoneBox SIM Activation</title>
      {showProratedPlanModal && step == 2 && (
        <ProratedPlanModal setShowModal={setShowProratedPlanModal} />
      )}
      {mobile ? (
        <></>
      ) : (
        <PageHeader
          text={"Review"}
          imageSrc="/images/activation/activation-header-icon.png"
        />
      )}
      <Container style={{ paddingBottom: "60px" }}>
        <Row className={step === 3 && "d-none"}>
          <Col sm={12} md={7}>
            <div className={"d-block"}>
              <SummaryBox mobile={mobile ? mobile : false}>
                <Row>
                  {mobile ? (
                    <>
                      <p
                        style={{
                          color: "#0494CA",
                          fontSize: "1.5rem",
                          fontWeight: 700,
                          paddingLeft: "10px",
                        }}
                      >
                        <Trans>Summary</Trans>
                      </p>
                      <Row>
                        <Text>
                          <Trans>Activation Date</Trans>:
                        </Text>
                      </Row>
                      <Row>
                        <Price>{valueFormatter("startDateLocal")}</Price>
                      </Row>
                    </>
                  ) : (
                    <>
                      <p
                        style={{
                          color: "black",
                          fontSize: "1.25em",
                          fontWeight: 700,
                        }}
                      >
                        <Trans>Summary</Trans>
                      </p>
                      <Row>
                        <Text>
                          <Trans>Activation Date</Trans>:
                        </Text>
                      </Row>
                      <Row>
                        <Price>{valueFormatter("startDateLocal")}</Price>
                      </Row>
                    </>
                  )}
                  <br />
                  <br />
                  <br />

                  {mobile ? (
                    <Col sm={12} md={5}>
                      <Box margin={"40px 0px"}>
                        <Row>
                          <Col sm={12} md={9}>
                            <SimCardDetail
                              planId={shopDetails.planId}
                              disableEdit={true}
                              imgDisplay={false}
                            />
                          </Col>
                        </Row>
                        <div className="pt-4">
                          <hr />
                        </div>
                        <Title
                          className="mt-4"
                          style={{ color: "black" }}
                        ></Title>
                        <div className="d-flex flex-row justify-content-start align-items-center"></div>
                        {shopDetails.currency === "CAD" && (
                          <Ul left="0" padding="0">
                            <Li size="17px">
                              <LiCont>
                                <Trans>UNLIMITED calling and messaging</Trans>
                              </LiCont>
                            </Li>
                            <Li>
                              <LiCont>
                                <Trans>
                                  UNLIMITED International text and picture
                                  messaging
                                </Trans>
                              </LiCont>
                            </Li>
                            {!isTelusPlan && (
                              <Li>
                                <LiCont>
                                  <Trans>
                                    Supported on the {shopDetails.planCarrier}{" "}
                                    Network
                                  </Trans>
                                </LiCont>
                              </Li>
                            )}
                            <Li>
                              <LiCont>
                                <Trans>Call Display</Trans>
                              </LiCont>
                            </Li>
                            <Li>
                              <LiCont>
                                <Trans>
                                  Voicemail - Up to 35 minutes of messages
                                </Trans>
                              </LiCont>
                            </Li>
                            <Li>
                              <LiCont>
                                <Trans>
                                  Data{" "}
                                  {shopDetails.isPostpaid
                                    ? "Overage"
                                    : "Top Up"}{" "}
                                  Rate: $15.00 / 1GB
                                </Trans>
                              </LiCont>
                            </Li>
                          </Ul>
                        )}

                        {shopDetails.country !== constants.CANADA &&
                          planIncludesDescription[shopDetails.planId] && (
                            <Ul left="0" padding="0">
                              {planIncludesDescription[shopDetails.planId].map(
                                (entry, i) => {
                                  if (entry.includes("75+ Countries")) {
                                    let index = entry.indexOf("75+ Countries");
                                    let firstPart = entry.substring(0, index);
                                    return (
                                      <Li size="20px" key={i}>
                                        <Trans>{firstPart}</Trans>
                                        <span
                                          style={{ color: "#22C3F1" }}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            Crisp.chat.openHelpdeskArticle(
                                              "en",
                                              "v4wb16"
                                            );
                                          }}
                                        >
                                          75+ <Trans>Countries</Trans>
                                        </span>
                                      </Li>
                                    );
                                  }

                                  return (
                                    <Li size="20px">
                                      <Trans>{entry}</Trans>
                                    </Li>
                                  );
                                }
                              )}
                            </Ul>
                          )}
                      </Box>
                    </Col>
                  ) : null}
                  <p
                    style={{
                      color: mobile ? "#0494CA" : "black",
                      fontSize: mobile ? "1.5rem" : "1.25em",
                      fontWeight: 700,
                      marginBlockStart: "revert",
                      paddingLeft: "10px",
                    }}
                  >
                    <Trans>Item Details</Trans>
                  </p>
                  <Row>
                    <Col>
                      <Text>
                        <Trans>SIM Card Fee</Trans>
                      </Text>
                    </Col>
                    <Col>
                      {!shopDetails.esim ? (
                        <Price details={"80px"}>
                          ${valueFormatter("simcard_fee")}
                        </Price>
                      ) : (
                        <Price details={"80px"}>$10.00</Price>
                      )}
                    </Col>
                  </Row>
                  {shopDetails.esim && (
                    <Row>
                      <Col>
                        <Text>
                          <Trans>eSIM Promotion</Trans>
                        </Text>
                      </Col>
                      <Col>
                        <Price details={"80px"}>-$10.00</Price>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col>
                      <Text>
                        <Trans>Shipping Fee</Trans>
                      </Text>
                    </Col>
                    <Col>
                      <Price details={"80px"}>${valueFormatter("sfFee")}</Price>
                    </Col>
                  </Row>

                  {shopDetails.isPostpaid ? (
                    <Row>
                      <Col>
                        <Text>
                          <Trans>Prorated Fee</Trans> <br />
                          <span
                            style={{
                              color: "#AEAFB1",
                              fontSize: mobile ? ".5rem" : "0.8em",
                            }}
                          >
                            {shopDetails.prorate_duration}
                          </span>
                        </Text>
                      </Col>
                      <Col>
                        <Price details={"80px"}>
                          ${valueFormatter("prorate_fee")}
                        </Price>
                      </Col>
                    </Row>
                  ) : null}

                  <Row>
                    <Col>
                      <Text>
                        <Trans>
                          {shopDetails.isPostpaid
                            ? "First Whole Month"
                            : "Plan Fee"}
                        </Trans>{" "}
                        <br />
                        <span
                          style={{
                            color: "#AEAFB1",
                            fontSize: mobile ? ".5rem" : "0.8em",
                          }}
                        >
                          {shopDetails.charge_duration}
                        </span>
                      </Text>
                    </Col>
                    <Col>
                      <Price details={"80px"}>
                        ${valueFormatter("plan_fee")}
                      </Price>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Text>
                        <Trans>Other</Trans>
                      </Text>
                    </Col>
                    <Col>
                      <Price details={"80px"}>
                        ${valueFormatter("promocredit")}
                      </Price>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Text>
                        <Trans>Sub Total</Trans>
                      </Text>
                    </Col>
                    <Col>
                      <Price details={"80px"}>
                        ${valueFormatter("subtotal")}
                      </Price>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Text>
                        <Trans>Taxes</Trans> (
                        {shopDetails.gst_rate + shopDetails.pst_rate}
                        %)
                      </Text>
                    </Col>
                    <Col>
                      <Price details={"80px"}>
                        $
                        {(shopDetails.gst_amt + shopDetails.pst_amt).toFixed(2)}
                      </Price>
                    </Col>
                  </Row>
                  <hr
                    style={{
                      marginRight: "20%",
                      width: mobile ? "3rem" : "100%",
                      marginLeft: mobile ? "14px" : 0,
                      marginTop: mobile ? "10px" : 0,
                    }}
                  />
                  <Row>
                    <Col>
                      <Text
                        style={{
                          fontSize: "18px",
                          fontWeight: 500,
                        }}
                      >
                        <Trans>Total</Trans>
                      </Text>
                    </Col>
                    <Col>
                      <Price
                        style={{
                          fontSize: "18px",
                          fontWeight: 500,
                        }}
                        details={"80px"}
                      >
                        ${valueFormatter("total")}
                      </Price>
                    </Col>
                  </Row>
                </Row>
              </SummaryBox>
              <div className="text-center">
                <MyBtn className="mx-3" onClick={goToCheckout}>
                  <Trans>Proceed to Checkout</Trans>
                </MyBtn>
              </div>
            </div>
          </Col>
          {mobile ? null : (
            <Col sm={12} md={5}>
              <Box>
                <Title style={{ color: "black" }}>
                  <Trans>Plan</Trans>
                </Title>
                <div className="pb-4">
                  <hr />
                </div>
                <Row>
                  <Col sm={12}>
                    <SimCardDetail
                      planId={shopDetails.planId}
                      disableEdit={true}
                    />
                  </Col>
                </Row>
                <div className="pt-4">
                  <hr />
                </div>
                <Title className="mt-4" style={{ color: "black" }}>
                  <Trans>Details</Trans>
                </Title>
                <div className="d-flex flex-row justify-content-start align-items-center"></div>
                {shopDetails.currency === "CAD" && (
                  <Detail>
                    {shopDetails.data + "" !== "Unlimited" && (
                      <DetailItem>
                        {formatPhoneData(shopDetails.data) +
                          " " +
                          t(
                            `high speed ${
                              isTelusPlan ? "4G LTE / 5G" : "5G"
                            } data`
                          )}
                      </DetailItem>
                    )}
                    <DetailItem>
                      <Trans>UNLIMITED Canada-wide calling</Trans>
                    </DetailItem>
                    <DetailItem>
                      <Trans>
                        UNLIMITED International text and picture messaging
                      </Trans>
                    </DetailItem>
                    {!isTelusPlan && (
                      <DetailItem>
                        <Trans>
                          Supported on the {shopDetails.planCarrier} Network
                        </Trans>
                      </DetailItem>
                    )}
                    <DetailItem>
                      <Trans>Call Display</Trans>
                    </DetailItem>
                    <DetailItem>
                      <Trans>Voicemail - Up to 35 minutes of messages</Trans>
                    </DetailItem>
                    <DetailItem>
                      <Trans>
                        Data {shopDetails.isPostpaid ? "Overage" : "TopUp"}{" "}
                        Rate: $15.00 / 1GB
                      </Trans>
                    </DetailItem>
                  </Detail>
                )}
                {shopDetails.country !== constants.CANADA &&
                  planIncludesDescription[shopDetails.planId] && (
                    <Detail>
                      {planIncludesDescription[shopDetails.planId].map(
                        (entry, i) => {
                          if (entry.includes("75+ Countries")) {
                            let index = entry.indexOf("75+ Countries");
                            let firstPart = entry.substring(0, index);
                            return (
                              <DetailItem key={i}>
                                <Trans>{firstPart}</Trans>
                                <span
                                  style={{ color: "#22C3F1" }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    Crisp.chat.openHelpdeskArticle(
                                      "en",
                                      "v4wb16"
                                    );
                                  }}
                                >
                                  75+ <Trans>Countries</Trans>
                                </span>
                              </DetailItem>
                            );
                          }
                          return (
                            <DetailItem>
                              <Trans>{entry}</Trans>
                            </DetailItem>
                          );
                        }
                      )}
                    </Detail>
                  )}
                <Title style={{ color: "black" }}>
                  <Trans>Please Note:</Trans>
                </Title>
                <Detail>
                  <DetailItem>
                    <Trans>Plan does not include International Roaming</Trans>
                  </DetailItem>
                  {shopDetails.country === "Canada" && (
                    <DetailItem>
                      <Trans>
                        Provincial 9-1-1 fees are extra (If applicable)
                      </Trans>
                    </DetailItem>
                  )}
                  {shopDetails.simcard_no === "" && (
                    <DetailItem>
                      <Trans>
                        A SIM card fee of $10 will be charged{" "}
                        {shopDetails.fromPartner && "(If applicable)"}
                      </Trans>
                    </DetailItem>
                  )}
                  {shopDetails.planId === 7 && (
                    <DetailItem>
                      <Trans>
                        International calling available as an add-on for $10
                        (available in May)
                      </Trans>
                    </DetailItem>
                  )}
                </Detail>
              </Box>
            </Col>
          )}
        </Row>
      </Container>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const Ul = styled.ul<{ padding?: string; left?: string }>`
  margin-left: ${({ left }) => left || "25px"};
  padding-left: ${({ padding }) => padding || "1rem"};
`;

const Li = styled.li<{ size?: string }>`
  font-size: 20px;
  font-weight: 600;
  list-style-type: none;
  @media (max-width: 440px) {
    font-size: 17px;
  }
`;

const LiCont = styled.div`
  display: flex;
  align-items: center;
`;

const Box = styled.div<{ margin?: string }>`
  background-color: #f8f8f8;
  border-radius: 20px;
  padding: 30px;
  margin: 40px 0px;
  @media (max-width: 768px) {
    margin: ${({ margin }) => margin || "40px 10px"};
    background-color: white;
    padding: 0px;
  }
`;
interface props {
  mobile: boolean;
}
const SummaryBox = styled.div<props>`
  background-color: ${({ mobile }) => (mobile ? "white" : "#f8f8f8")};
  border-radius: 20px;
  padding: 40px 80px;
  margin: 40px 0px;
  @media (max-width: 768px) {
    margin: 40px 10px;
    padding: 40px 30px;
  }
`;

const Title = styled.p`
  color: #0594ca;
  font-weight: 500;
  font-size: 20px;
`;

const Text = styled.div<{ topGap?: string; bottomGap?: string }>`
  font-size: 16px;
  color: black;
  font-weight: 500;
  margin-top: ${(props) => props.topGap};
  margin-bottom: ${(props) => props.bottomGap};
  width: max-content;
  span {
    color: red;
  }

  @media (max-width: 456px) {
    font-size: 17px;
  }
`;

const Detail = styled.ul`
  margin: 30px 0px;
`;

const DetailItem = styled.li`
  font-size: 16px;
  font-weight: 300;
  margin: 10px 0px;
`;

const MyBtn = styled.button`
  font-size: 18px;
  font-weight: 400;
  transition: 0.7s;
  color: #fff;
  margin: 0px;
  outline: none !important;
  padding: 8px 40px;
  border-radius: 50px;
  display: inline-block;
  cursor: pointer;
  position: relative;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  background-image: linear-gradient(to bottom, #0194e9, #35c1d0);
  border: none;
  margin: 15px 0px;
  :hover {
    color: #fff;
  }
`;
const Price = styled(Text)<{ details?: string }>`
  color: #01bae5;
  font-weight: 500;
  font-size: 17px;
  margin-left: ${({ details }) => details || 0};
  @media (max-width: 520px) {
    float: right;
    margin-left: 0;
  }
`;
